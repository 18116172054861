body {
  margin: 0;
  font-family: "NewHero", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "NewHero";
  src: local("NewHero"),
    url(./../public/fonts/NewHero-Light.woff2) format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "NewHero";
  src: local("NewHero"),
    url(./../public/fonts/NewHero-Regular.woff2) format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "NewHero";
  src: local("NewHero"),
    url(./../public/fonts/NewHero-Bold.woff2) format("woff2");
  font-weight: 500;
}
